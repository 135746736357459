import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TinyColor2 from 'tinycolor2';

import { GenericIcon, LinkBlank } from '@mssgme/ui';
import { ua, urlSafeComponent } from '@mssgme/helpers';
import { prettifyUrl, __t } from '@mssgme/shared';
import { usePageMeta } from '../../../../hooks';

import styles from './LinkButton.scss';

export default function LinkButton({ block, link, interactive, theme, style, className, onClick, ...rest }) {
    const { title, name, url } = usePageMeta(link.page) || link;
    const iconBorderColor = TinyColor2(style.backgroundColor)
        .darken(4)
        .toString();
    const Tag = interactive ? LinkBlank : 'div';

    const handleClick = (e) => {
        if (!interactive) {
            return e.preventDefault();
        }

        if (onClick) {
            e.preventDefault();

            return onClick({ link });
        }

        if (ua.Android) {
            e.preventDefault();

            const { currentTarget } = e;
            const href = currentTarget.href;

            window.open(href);
        }
    };

    return (
        <div className={cn(styles.wrapper, styles[theme.width])}>
            <Tag
                data-element="custom-link"
                data-event-name={urlSafeComponent(url)}
                data-id={block._id}
                data-title={title || name || prettifyUrl(url)}
                id={block._id}
                className={cn(styles.root, theme.icon && styles.hasIcon, className, 'link-button')}
                href={url}
                style={style}
                onClick={handleClick}
                {...rest}
            >
                {theme.icon && (
                    <div className={styles.icon} style={{ borderRight: `1px solid ${iconBorderColor}` }}>
                        <GenericIcon name={theme.icon} size={20} />
                    </div>
                )}

                <div className={styles.content}>
                    <div>{title || name || prettifyUrl(url) || __t('link_placeholder')}</div>
                    {theme.urlSubtitle && <div className={styles.subTitle}>{prettifyUrl(url)}</div>}
                </div>
            </Tag>
        </div>
    );
}

LinkButton.propTypes = {
    link: PropTypes.object.isRequired,
    block: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    interactive: PropTypes.bool.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

LinkButton.defaultProps = {
    className: '',
    onClick: null,
};
