import PropTypes from 'prop-types';
import React from 'react';

import { cls } from '@mssgme/helpers';
import { useUploads } from '../../hooks';
import styles from './BackgroundImage.scss';

export function BackgroundImage({ path, opacity, ...rest }) {
    const { getMediaSizeUrl } = useUploads();
    const srcset = `${getMediaSizeUrl(path, 'medium')} 750w, ${getMediaSizeUrl(path, 'normal')} 1920w, ${getMediaSizeUrl(path, 'large')} 2560w`;
    const sizes = '(max-width: 600px) 750w, (max-width: 1920px) 1920w, (max-width: 2560px) 2560w';

    return (
        <div {...cls(styles.root, rest)}>
            <img
                srcSet={srcset}
                sizes={sizes}
                src={getMediaSizeUrl(path, 'normal')}
                alt="background image" style={{ opacity }}
            />
        </div>
    );
}

BackgroundImage.propTypes = {
    path: PropTypes.string.isRequired,
    opacity: PropTypes.number,
    style: PropTypes.object,
};

BackgroundImage.defaultProps = {
    opacity: 1,
    style: {},
};
