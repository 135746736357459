import PropTypes from 'prop-types';
import React from 'react';

import { LinkBlockKind } from '@mssgme/shared';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase, useBlockHorizontalInsetsPadding } from '../BlockBase';
import LinkButton from './LinkButton';

export default function Link({ block, onClick, insets, interactive, ...rest }) {
    const { theme, style } = useBlockTheme(block);
    const blockInsets = useBlockHorizontalInsetsPadding(insets, theme.padding);

    return (
        <BlockBase insets={blockInsets} {...rest}>
            <LinkButton
                link={block.link}
                block={block}
                theme={theme}
                style={style}
                interactive={interactive}
                onClick={onClick}
            />
        </BlockBase>
    );
}

Link.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
    onClick: PropTypes.func,
};

Link.defaultProps = {
    ...BlockBase.defaultProps,
    onClick: null,
};

Link.kind = LinkBlockKind;
