import PropTypes from 'prop-types';
import React, { Suspense, useMemo, useRef } from 'react';
import { useMeasure } from 'react-use';

import { useMergedRefs } from '@mssgme/hooks';
import { keyHash } from '@mssgme/helpers';
import { InsetPropType, Insets, useBlockHorizontalInsetsPadding } from '../../UI';

export const BlockBase = React.forwardRef(function BlockBase(
    {
        embedded,
        insets,
        interactive, // eslint-disable-line no-unused-vars
        sortable, // eslint-disable-line no-unused-vars
        isViewMode, // eslint-disable-line no-unused-vars
        isMobileMode, // eslint-disable-line no-unused-vars
        lazy,
        style: baseStyle,
        as: Tag = 'div',
        ...rest
    },
    ref
) {
    const style = useMemo(() => (embedded ? baseStyle : Insets.resolveInsets(insets, baseStyle)), [
        embedded,
        keyHash(insets),
        keyHash(baseStyle),
    ]);

    if (lazy) {
        return <LazyBlockBase ref={ref} as={Tag} style={style} {...rest} />;
    }

    return <Tag ref={ref} style={style} {...rest} />;
});

BlockBase.unpackInsets = Insets.unpackInsets;
BlockBase.resolveInsets = Insets.resolveInsets;
BlockBase.removeHorizontalInsets = Insets.removeHorizontalInsets;

BlockBase.propTypes = {
    embedded: PropTypes.bool,
    interactive: PropTypes.bool,
    lazy: PropTypes.bool,
    isViewMode: PropTypes.bool,
    isMobileMode: PropTypes.bool,
    sortable: PropTypes.bool,
    style: PropTypes.any,
    as: PropTypes.elementType,
    insets: PropTypes.shape({
        margin: InsetPropType.isRequired,
        padding: InsetPropType.isRequired,
    }),
};

BlockBase.defaultProps = {
    interactive: true,
    embedded: false,
    sortable: false,
    style: null,
    isMobileMode: true,
    insets: Insets.normalInsets,
};

const LazyBlockBase = React.forwardRef(function LazyBlockBase({ style, children, as: Tag = 'div', ...rest }, ref) {
    const [measureRef, { height }] = useMeasure();
    const htmlRef = useRef();
    const divRef = useMergedRefs([ref, measureRef, htmlRef]);
    const styleFixed = useMemo(() => ({ ...style, height }), [height, keyHash(style)]);
    const __html = htmlRef.current?.innerHTML;
    const html = useMemo(() => ({ __html }), [__html]);

    return (
        <Suspense fallback={<Tag ref={divRef} style={styleFixed} data-lazy dangerouslySetInnerHTML={html} {...rest} />}>
            <Tag ref={divRef} style={style} {...rest}>
                {children}
            </Tag>
        </Suspense>
    );
});

LazyBlockBase.propTypes = BlockBase.propTypes;

export { useBlockHorizontalInsetsPadding };
