import { useCallback } from 'react';
import { mediaUtils } from '@mssgme/shared';

export const MediaSizes = mediaUtils.MediaSizes;

/**
 * @returns {{
 *   getMediaSizeUrl: function(url:string, size:number): string
 * }} Returns upload helpers
 */
export const useUploads = () => {
    const getMediaSizeUrl = useCallback((url, size = MediaSizes.Original) => {
        return size === MediaSizes.Original
            ? mediaUtils.generateAbsoluteUrl(url)
            : mediaUtils.generateThumbnailUrl(url, size);
    }, []);

    return {
        getMediaSizeUrl,
    };
};
