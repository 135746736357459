import { useCallback } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';

import { resolvePathname } from '@mssgme/helpers';

/**
 * @param {{ useMatchUrl: boolean }} [defaults] Generator default options
 * @returns {{ generateUrl: function(path: string, options?: { useMatchUrl: boolean }): string }} Generator method
 */
export function useNavigationUtils(defaults) {
    const match = useRouteMatch();
    const location = useLocation();

    const generateUrl = useCallback(
        (path, options) => {
            const isRelative = path.charAt(0) !== '/';

            if (isRelative) {
                options = defaults ? { ...defaults, ...options } : options;

                return resolvePathname(options?.useMatchUrl ? match.url : location.pathname, path);
            }

            return resolvePathname(path);
        },
        [match.url, location.pathname, defaults?.useMatchUrl]
    );

    return { generateUrl };
}
