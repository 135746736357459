import { isEmpty, isMatch as _isMatch, omit } from 'lodash';

export const tidyFilter = (filter) => Object.fromEntries(Object.entries(filter).filter(([, v]) => v ?? (v !== '')));

export const isMatch = (...args) => (i) => _isMatch(i, ...args);
export const isMatchId = (id) => isMatch({ _id: id });
export const isExact = (a) => (b) => a === b;
export const isSubstring = (a) => {
    const match = String(a).toLowerCase();

    return (b) => {
        return String(b).toLowerCase().includes(match);
    };
};

const byEquality = (value, key) => (item) => item[key] === value;
const bySubstring = (value, key) => {
    const cmp = isSubstring(value);

    return (item) => cmp(item[key] || '');
};
const byProps = (props, isEqual = isExact) => (value) => {
    const cmp = isEqual(value);

    return (item) => props.some((prop) => cmp(item[prop]));
};

const mapping = {
    0: byEquality,
    1: bySubstring,
};
const match = (items, matchers = {}) => {
    const reducer = (result, [key, value]) => {
        const mapKey = matchers[key];

        if (mapKey === -1) {
            // ignore filter
            return result;
        }

        const matcher = typeof mapKey === 'function' ? mapKey : mapping[mapKey] || byEquality;
        const predicate = matcher(value, key);

        if (!result.every(predicate)) {
            return result.filter(predicate);
        }

        return result;
    };

    return (filters) => Object.entries(filters).reduce(reducer, items);
};

const filterWithSpecifier = (filter, invert) => {
    const pairs = Object.entries(filter);

    return (item) => !!(pairs.every(([key, value]) => !value || item[key] === value) ^ !!invert);
};

const filterWithExclusion = (items, specifier) => {
    if (!specifier) {
        return items;
    }

    const filter = specifier.filter;
    const exclude = specifier.exclude;

    const hasFilter = !!filter;
    const hasExclusion = !isEmpty(exclude);
    const query = hasFilter || hasExclusion ? omit(specifier, ['filter', 'exclude']) : specifier;

    if (hasFilter) {
        items = items.filter(filter);
    }

    if (hasExclusion) {
        items = items.filter(filterWithSpecifier(exclude, true));
    }

    if (!isEmpty(query)) {
        items = items.filter(filterWithSpecifier(query));
    }

    return items;
};

export default {
    match,
    isMatch,
    isMatchId,
    isExact,
    isSubstring,
    byEquality,
    bySubstring,
    byProps,
    filterWithSpecifier,
    filterWithExclusion,
};
