import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMount } from 'react-use';
import { Switch, Route } from 'react-router-dom';

import { cls, isMobile } from '@mssgme/helpers';
import { LandingsPropTypes, ThemeContext, InnerHTMLWithScripts } from '@mssgme/ui';
import { PageDataContext, RendererContext, useResolvedTheme, WebsiteDataContext } from '../../hooks';
import { Insets, BuyerActions } from '../../UI';
import { BLOCKS_MAP, PageProductsRouting } from '../PageBlocks';
import { BackgroundImage } from '../BackgroundImage';
import { BrandingBottom } from '../BrandingBottom';

import styles from './Page.scss';

export const Page = React.forwardRef(function Page(
    { website, page, interactive, embedded, contentClass, footer, header, ...rest },
    innerRef
) {
    const contentRef = useRef(null);
    const blocks = page.blocks.filter((block) => block.enabled && !block.nested);
    const [allBlocksCount, setAllBlocksCount] = useState(page.blocks.length);
    const [blocksCount, setBlocksCount] = useState(blocks.length);
    const { theme, style, resolvedTheme, align } = useResolvedTheme(page);
    const isMobileMode = !isMobile();

    const renderBlock = useMemo(
        // eslint-disable-next-line react/display-name
        () => (block, index) => {
            const Component = BLOCKS_MAP[block.kind];
            const insets = Insets.computeComponentInsets(blocks, block, index, resolvedTheme);
            const key = block._id || `${block.kind + index}`;

            return Component ? (
                <Component
                    key={key}
                    insets={insets}
                    block={block}
                    interactive={interactive}
                    isMobileMode={isMobileMode}
                    data-block={block._id}
                    data-block-kind={block.kind}
                />
            ) : (
                <div key={key} data-block={block._id} data-block-kind={block.kind}>
                    Unknown block kind &quot;{block.kind}&quot;
                </div>
            );
        },
        [interactive, isMobileMode, blocks, resolvedTheme]
    );

    useEffect(() => {
        const scrollTop = contentRef.current.scrollHeight - contentRef.current.clientHeight;

        if (page.blocks.length > allBlocksCount && blocks.length > blocksCount) {
            contentRef.current.scroll({ top: scrollTop, behavior: 'smooth' });
        }

        setAllBlocksCount(page.blocks.length);
        setBlocksCount(blocks.length);
    }, [blocks.length, page.blocks.length]);

    useMount(() => {
        if (!embedded && isMobile()) {
            Object.assign(document.body.style, style);
        }
    });

    return (
        <WebsiteDataContext.Provider value={website}>
            <PageDataContext.Provider value={page}>
                <ThemeContext.Provider value={resolvedTheme}>
                    <RendererContext.Provider value={renderBlock}>
                        <div
                            id="page_root"
                            ref={innerRef}
                            {...cls(
                                [styles.root, isMobileMode && styles.mobileMode, embedded && styles.embedded],
                                rest
                            )}
                            style={style}
                        >
                            {theme.backgroundImage && (
                                <BackgroundImage
                                    path={theme.backgroundImage}
                                    opacity={theme.backgroundImageOpacity}
                                />
                            )}
                            <div className={styles.rootInner} id="page_root_inner">
                                <div ref={contentRef}
                                    className={cn(styles.content, contentClass)}
                                    style={align.container}>
                                    {header}

                                    <div id="blocks-container" style={align.inner}>
                                        {blocks.map(renderBlock)}
                                    </div>

                                    {website.embeddedCode?.onLoad && website.embeddedCode.content && (
                                        <InnerHTMLWithScripts html={website.embeddedCode.content} />
                                    )}

                                    {website.branding && <BrandingBottom />}
                                    {footer}
                                </div>
                            </div>
                            <div className={styles.overlayContainer}>
                                <div className={styles.overlayContainerContent}>
                                    {!embedded &&
                                        <BuyerActions />
                                    }
                                </div>
                            </div>
                            <React.Suspense fallback={null}>
                                <Switch>
                                    <Route
                                        path={['/c/', '/p/', '/user-orders/', '/orders/']}
                                        component={PageProductsRouting}
                                    />
                                </Switch>
                            </React.Suspense>
                        </div>
                    </RendererContext.Provider>
                </ThemeContext.Provider>
            </PageDataContext.Provider>
        </WebsiteDataContext.Provider>
    );
});

Page.propTypes = {
    website: LandingsPropTypes.website.isRequired,
    page: LandingsPropTypes.page.isRequired,
    interactive: PropTypes.bool,
    embedded: PropTypes.bool,
    contentClass: PropTypes.string,
    header: PropTypes.node,
    footer: PropTypes.node,
};

Page.defaultProps = {
    interactive: true,
};
