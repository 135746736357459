import ExtendableError from 'es6-error';

import { parseErrors } from './helpers';

export class ApiError extends ExtendableError {
    name = 'ApiError';
    errors = {};
    status = 500;

    /**
     * @param {{ status: number; message: string; json: object }} raw Error data
     */
    constructor(raw) {
        super();
        Object.assign(this, raw);
    }

    static is(error) {
        return !!error && (this.name === error.name || this.type === error.type || error instanceof this);
    }

    get type() {
        return this.name;
    }
}

export class NetworkError extends ExtendableError {
    name = 'NetworkError';
}

export class RequestError extends ApiError {
    name = 'RequestError';
}

export class NotFoundError extends ApiError {
    name = 'NotFoundError';

    /**
     * @param {{ status: number; message: string; json: object }} data Error data
     */
    constructor(data) {
        super({ status: 404, message: 'NotFound error', ...data });
    }
}

export class ValidationError extends ApiError {
    name = 'ValidationError';

    /**
     * @param {{ status?: number; message?: string; json: object }} data Error data
     */
    constructor(data) {
        super({ status: 400, message: 'Validation error', errors: parseErrors(data.json), ...data });
    }
}

export class AuthorizationError extends ApiError {
    name = 'AuthorizationError';

    /**
     * @param {{
     *  status: number;
     *  message: string;
     *  json?: {} | { permission: string; code: string; plan: string; target: string }
     * }} data Error data
     */
    constructor(data) {
        super({ status: 401, message: 'Authorization error', ...data });
        const json = data.json;

        if (json?.permission) {
            this.permission = json.permission;
            this.code = json.code;
            this.plan = json.plan;
            this.target = json.target;
        }
    }

    isFeatureAccessError() {
        return !!this.permission;
    }
}

export class ForbiddenError extends ApiError {
    name = 'ForbiddenError';

    /**
     * @param {{
     *   status: number;
     *   message: string;
     *   json: { error?: Error & { permission?: string; details?: { plan: string; target?: string } } }
     * }} data Error data
     */
    constructor({ status, message, json: { error } }) {
        super({
            status: status || 403,
            message: error?.message || message || 'Forbidden',
            permission: error?.permission,
            code: error?.code,
            ...error?.details,
        });
    }

    static isPermissionBlock(e) {
        return this.is(e) && !!e.permission;
    }
}

export class LimitRequestsError extends ApiError {
    name = 'LimitRequestsError';

    /**
     * @param {{ status: number; message: string; json: { error?: string; within?: number } }} data Error data
     */
    constructor(data) {
        super({ status: 429, message: data.json.error || 'Too Many Requests', within: data.json.within, ...data });
    }
}

export class LimitLoginRequestsError extends LimitRequestsError {
    name = 'LimitLoginRequestsError';
}
