import { createActionCreators, createActionCreator } from '@mssgme/api-client';

import { FrontApiClient } from '../../lib/storefrontApi';

export { FrontApiClient };

export const frontApiClient = new FrontApiClient({ baseUrl: '/storefront/api' });
export const clearOrder = createActionCreator('CLEAR_ORDER');
export const storefrontApiActions = createActionCreators(frontApiClient, {
    clearOrder,
});
