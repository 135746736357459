export const CRM_IDENTITY = {
    defaultCurrency: 'USD',
    contacts: { messengers: [] },
    payments: {
        cod: {
            enabled: false,
        },
        paypal: {
            clientId: '',
            enabled: false,
        },
        stripe: {
            publicKey: '',
            secretKey: '',
            enabled: false,
        },
        wayforpay: {
            merchantAccount: '',
            merchantSecretCode: '',
            enabled: false,
        },
        robokassa: {
            merchantLogin: '',
            merchantPassword: '',
            test: false,
            enabled: false,
        },
        liqpay: {
            publicKey: '',
            privateKey: '',
            enabled: false,
        },
        other: {
            enabled: false,
        },
    },
    form: {
        collectAddress: true,
        successMessage: '',
        successURL: { url: '', title: '' },
        optional: {
            city: false,
            state: false,
            postalCode: false,
            lastName: false,
        },
    },
};
