import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { useNavigationUtils } from '@mssgme/hooks';
import { ClipboardIcon } from '@mssgme/icons';
import { ShoppingCart } from '../ShoppingCart';
import { useWebsiteData, useSavedOrders } from '../../hooks';

import styles from './BuyerActions.scss';

export const BuyerActions = (props) => {
    const { generateUrl } = useNavigationUtils();
    const { enabledPayments } = useWebsiteData();
    const { currentOrder, userOrders } = useSavedOrders();
    const hasFinalizedOrders = userOrders.filter((order) => order.finalized).length > 0;
    const isSingleOrdersButton = !(enabledPayments && currentOrder?.items.length);

    return (
        <div className={styles.root} {...props}>
            {hasFinalizedOrders && (
                <Link
                    to={generateUrl('user-orders')}
                    className={cn([styles.ordersButton, isSingleOrdersButton && styles.single])}
                >
                    <ClipboardIcon size={isSingleOrdersButton ? 20 : 16} />
                </Link>
            )}
            <ShoppingCart steady />
        </div>
    );
};
