import React, { useContext, useMemo } from 'react';

import { isMatchId } from '@mssgme/helpers';
import { CRM_IDENTITY } from '../../products';

export const WebsiteDataContext = React.createContext({
    _id: '',
    workspace: '',
    name: '',
    slug: '',
    absoluteUrl: '',
    finalUrl: '',
    homePageId: '',
    collections: [],
    pages: [],
    seo: {},
    localization: {},
    crm: CRM_IDENTITY,
});

export const useWebsiteData = () => useContext(WebsiteDataContext);

export const usePageMeta = (pageId) => {
    const website = useContext(WebsiteDataContext);
    const page = pageId && website.pages.find(isMatchId(pageId));

    return useMemo(
        () =>
            page && {
                ...page,
                name: website.homePageId === page._id ? website.name : page.name || website.name,
                url: page.absoluteUrl,
            },
        [website.homePageId, website.name, page]
    );
};
