import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { LanguageContext } from '@mssgme/hooks';
import { ExperimentsProvider, NotificationsBag, ThemeContext } from '@mssgme/ui';

import { useAutoTheme, WebsiteDataContext } from '../hooks';
import { Page } from '../Page';
import { PastDueModal } from '../UI';

import styles from './Website.scss';

const ab = {
    experiments: [
        {
            id: 'branding',
            variants: [
                { id: 'medium', weight: 50 },
                // { id: 'sticky', weight: 50 },
            ],
        },
    ],
};

export function Website({ basename, data: { website, page: pageId }, lang = 'en' }) {
    const page = website.pages.find(({ _id }) => _id === pageId);
    const { theme } = useAutoTheme(page.theme);

    return (
        <Router basename={basename}>
            <ExperimentsProvider config={ab}>
                <LanguageContext.Provider value={lang}>
                    <ThemeContext.Provider value={theme}>
                        <WebsiteDataContext.Provider value={website}>
                            <div className={styles.root} id="website_root">
                                <NotificationsBag />
                                <div className={styles.content}>
                                    <Page
                                        website={website}
                                        page={page}
                                        footer={website.unpublishedAt && <PastDueModal />}
                                    />
                                </div>
                            </div>
                        </WebsiteDataContext.Provider>
                    </ThemeContext.Provider>
                </LanguageContext.Provider>
            </ExperimentsProvider>
        </Router>
    );
}

Website.propTypes = {
    basename: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    lang: PropTypes.string,
};
