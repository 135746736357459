import PropTypes from 'prop-types';
import { memoize } from 'lodash';
import { useMemo } from 'react';

import {
    CustomBlockKind,
    DividerBlockKind,
    HatBlockKind,
    HeadingBlockKind,
    LinkBlockKind,
    TextBlockKind,
} from '@mssgme/shared';
import { resolveBlockTheme } from './elementTheming';

const fullGap = 16;
const halfGap = fullGap / 2;
const fullPull = -fullGap;
const halfPull = -halfGap;
const identityVertical = { top: 0, bottom: 0 };
const identityHorizontal = { left: 0, right: 0 };
const identityInset = { left: 0, top: 0, right: 0, bottom: 0 };
const identityInsets = { margin: 0, padding: 0 };
const normalInsets = { margin: fullGap, padding: 0 };
const custom = { margin: halfGap, padding: 0 };
const fullPullTop = {
    padding: 0,
    margin: {
        right: fullGap,
        bottom: fullGap,
        left: fullGap,
        top: fullPull,
    },
};
const flushTop = {
    padding: 0,
    margin: {
        right: fullGap,
        bottom: fullGap,
        left: fullGap,
        top: 0,
    },
};
const flushTop60 = {
    padding: 0,
    margin: {
        right: fullGap,
        bottom: fullGap,
        left: fullGap,
        top: 60,
    },
};
const halfPullTop = {
    padding: 0,
    margin: {
        right: fullGap,
        bottom: fullGap,
        left: fullGap,
        top: halfPull,
    },
};

const fullPullDynamic = memoize(
    (pullTop, pullBottom) => ({
        padding: normalInsets.padding,
        margin: {
            top: pullTop ? fullPull : 0,
            bottom: pullBottom ? fullPull : 0,
        },
    }),
    (t, b) => `${+t}${+b}`
);

const hashInset = (inset) =>
    typeof inset === 'object' ? `${inset.top}:${inset.left}:${inset.right}:${inset.bottom}` : String(inset);

const resolveInset = (inset) => {
    if (typeof inset === 'object') {
        return `${inset.top || 0}px ${inset.right || 0}px ${inset.bottom || 0}px ${inset.left || 0}px`;
    }

    return inset;
};

const resolveInsets = (insets, base) => {
    if (insets === undefined || insets === null) {
        return base;
    }

    return {
        margin: insets.margin && resolveInset(insets.margin),
        padding: insets.padding && resolveInset(insets.padding),
        ...base,
    };
};

const unpackInsets = (inset) => {
    const typeOf = typeof inset;

    if (inset && typeOf === 'object') {
        return {
            top: typeof inset.top === 'number' ? inset.top : 0,
            right: typeof inset.right === 'number' ? inset.right : 0,
            bottom: typeof inset.bottom === 'number' ? inset.bottom : 0,
            left: typeof inset.left === 'number' ? inset.left : 0,
        };
    }

    if (typeOf === 'number') {
        return {
            top: inset,
            right: inset,
            bottom: inset,
            left: inset,
        };
    }

    return identityInset;
};

const removeHorizontalInsets = (inset) => {
    if (inset) {
        const { top, bottom } = inset;

        return {
            top: top || 0,
            bottom: bottom || 0,
        };
    }

    return identityVertical;
};

const removeVerticalInsets = (inset) => {
    if (inset) {
        const { left, right } = inset;

        return {
            left: left || 0,
            right: right || 0,
        };
    }

    return identityHorizontal;
};

const scaleInsets = ({ left, top, right, bottom }, scale) => {
    return {
        left: left * scale,
        top: top * scale,
        right: right * scale,
        bottom: bottom * scale,
    };
};

const computeComponentInsets = (blocks, block, index, theme) => {
    switch (block.kind) {
        case HatBlockKind: {
            const isFirstBlock = index === 0;

            if (isFirstBlock) {
                const isTopLevelAvatar = isFirstBlock && resolveBlockTheme(theme, block).headerMode === 'avatar';

                return isTopLevelAvatar ? flushTop60 : flushTop;
            }

            break;
        }

        case CustomBlockKind:
            return custom;

        case DividerBlockKind: {
            const prev = blocks[index - 1]?.kind;
            const next = blocks[index + 1]?.kind;
            const isFirstBlock = index === 0;
            const isLastBlock = blocks.length - 1 === index;

            return fullPullDynamic(
                !(isFirstBlock || prev === DividerBlockKind),
                !(isLastBlock || next === DividerBlockKind || next === HatBlockKind)
            );
        }

        case TextBlockKind: {
            const isAfterHeading = blocks[index - 1]?.kind === HeadingBlockKind;

            if (isAfterHeading) {
                return fullPullTop;
            }

            break;
        }

        case LinkBlockKind: {
            const isAfterLink = blocks[index - 1]?.kind === LinkBlockKind;

            if (isAfterLink) {
                return halfPullTop;
            }

            break;
        }
    }

    return normalInsets;
};

export const Insets = {
    identityInsets,
    normalInsets,
    fullPull,
    halfPull,
    custom,
    fullPullDynamic,
    fullPullTop,
    halfPullTop,
    flushTop,
    hashInset,
    resolveInsets,
    unpackInsets,
    removeHorizontalInsets,
    removeVerticalInsets,
    scaleInsets,
    computeComponentInsets,
};

export function useBlockHorizontalInsetsPadding(insets, padding) {
    return useMemo(
        () =>
            padding ? insets : { padding: insets.padding, margin: removeHorizontalInsets(unpackInsets(insets.margin)) },
        [padding, insets && hashInset(insets)]
    );
}

export function useDoubleNestedInsets(insets, padding) {
    return useMemo(() => {
        const baseMargin = unpackInsets(insets.margin).left;
        const margin = padding ? baseMargin / 2 : 0;

        return {
            margin,
            resolvedInsets: {
                margin: { top: baseMargin, bottom: baseMargin },
                padding: { left: margin, right: margin },
            },
        };
    }, [padding, insets.margin]);
}

export const InsetPropType = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
    }),
]);
