import { storefrontApiActions } from '../actions';

function ordersReducer(state = [], action) {
    switch (action.type) {
        case storefrontApiActions.orders.list.successType:
            return action.result;
        case storefrontApiActions.orders.create.successType:
            return [action.result, ...state];
        case storefrontApiActions.orders.update.successType:
        case storefrontApiActions.orders.modify.successType:
        case storefrontApiActions.orders.finalize.successType:
        case storefrontApiActions.orders.cancel.successType:
            if (action.result.items?.length) {
                return state.map((order) => (order._id === action.result._id ? action.result : order));
            } else {
                return state.filter((order) => order._id !== action.result._id);
            }
        default:
            return state;
    }
}

export default ordersReducer;
