import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { cls } from '@mssgme/helpers';
import { useNavigationUtils } from '@mssgme/hooks';
import { ShoppingCartIcon } from '@mssgme/icons';
import { useWebsiteData, useSavedOrders } from '../../hooks';

import styles from './ShoppingCart.scss';

export const ShoppingCart = ({ steady, ...rest }) => {
    const { generateUrl } = useNavigationUtils();
    const { enabledPayments } = useWebsiteData();
    const { currentOrder } = useSavedOrders();
    const shouldLoad = enabledPayments && currentOrder?.items.length;

    const orderUrl = useMemo(() => generateUrl(`orders/${currentOrder?._id}`), [currentOrder, generateUrl]);

    if (!shouldLoad) {
        return null;
    }

    return (
        <Link to={orderUrl} {...cls(cn([styles.root, steady && styles.steady]), rest)}>
            <ShoppingCartIcon className={styles.icon} size={20} />
            <div className={styles.badge}>{currentOrder.items.length}</div>
        </Link>
    );
};

ShoppingCart.propTypes = {
    steady: PropTypes.bool,
};
