import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { deepBindActionCreators } from '@mssgme/helpers';
import { frontApiClient, storefrontApiActions } from '../store';
import { useWebsiteData } from './useWebsiteData';

export const BACKEND_ENDPOINTS = {
    getBaseUrl({ workspace, _id }) {
        return `${document.location.origin}/storefront/api/workspaces/${workspace}/orders/${_id}/signatures`;
    },
    getWayforpayCallback(order) {
        return `${this.getBaseUrl(order)}/wayforpay/callbacks`;
    },
    getLiqPayCallback(order) {
        return `${this.getBaseUrl(order)}/liqpay/callbacks`;
    },
};

export const useFrontAPI = () => {
    const dispatch = useDispatch();
    const { workspace } = useWebsiteData();

    return useMemo(() => {
        frontApiClient.param('workspaceId', workspace);

        return deepBindActionCreators(storefrontApiActions, dispatch);
    }, [dispatch]);
};
