import React, { useRef } from 'react';

/**
 * Bundle several components to lazy-load together.
 *
 * @param {function(): Promise<{ default: * }>} factory Import loader
 * @returns {{}} Lazy-loadable bundle
 */
export default (factory) => {
    let bundle;
    let promise;

    /**
     * Returns cached chunk loader.
     *
     * @returns {Promise<*>} Promise of chunk load.
     */
    const preload = async () => {
        promise ||= new Promise((resolve, reject) => {
            if (bundle) {
                return resolve(bundle);
            }

            factory()
                .then((loaded) => resolve((bundle = loaded)))
                .catch(reject);
        });

        return promise;
    };

    return new Proxy(
        {},
        {
            get: (target, kind) => {
                const has = target[kind];

                if (has) {
                    return has;
                }

                let preloaded = bundle?.[kind];
                /**
                 * Lazy component. If the bundle is already loaded,
                 * the component from the bundle would be used directly instead.
                 *
                 * @type {React.LazyExoticComponent<*>}
                 */
                const lazy = React.lazy(async () => {
                    bundle ||= await preload();
                    preloaded = bundle[kind];

                    return {
                        default: preloaded,
                    };
                });
                const Wrapper = React.forwardRef(function Wrapped(props, ref) {
                    // Component should not be changed afterwards (to prevent remount)
                    const componentRef = useRef(preloaded ?? lazy);

                    return ref ? <componentRef.current ref={ref} {...props} /> : <componentRef.current {...props} />;
                });
                const Component = Object.assign(Wrapper, { kind, preload });

                target[kind] = Component;

                return Component;
            },
        }
    );
};
